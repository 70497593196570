import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import SEO,{SEO_description} from './components/SEO';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './pages/Home';
import CareersPage from './pages/Careers';
import CarRepairSupportPage from './pages/apps/car-repair/CarRepairSupport'
import CarRepairPrivacy from './pages/apps/car-repair/CarRepairPrivacy'
import InstaCalMarketingPage from './pages/apps/insta-cal/InstaCal'
import InstaCalPrivacyPolicyPage from './pages/apps/insta-cal/InstaCalPrivacy'
import InstaCalSupportPage from './pages/apps/insta-cal/InstaCalSupport';
import InstaCalTerms from './pages/apps/insta-cal/InstaCalTerms';
function App() {
 
  return (
    <HelmetProvider>
      <SEO
        description={SEO_description}
        name="Code Nurturers LLC"
        type="article"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/careers" element={<CareersPage/>}/>
          <Route path="/car-repair/support" element={<CarRepairSupportPage />} />
          <Route path="/car-repair/privacy" element={<CarRepairPrivacy />}/>
          <Route path="/instacal" element={<InstaCalMarketingPage />} />
          <Route path="/instacal/support" element={<InstaCalSupportPage />} />
          <Route path="/instacal/privacy-policy" element={<InstaCalPrivacyPolicyPage />} />
          <Route path="/instacal/terms" element={<InstaCalTerms />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
