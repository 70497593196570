import { Link } from "react-router-dom"
export default function SupportPage() {
    return (
        <div style={{padding: '8px'}}>
            <h1>Frequently Asked Questions</h1>

            <h2>1. What is Car Repair Demo?</h2>
            <p>
                Car Repair Demo is a demo mobile application to demonstrate various mobile app functionalities including; mobile release banners, mobile unit and integration testing, crash monitoring.
                All data is fictional, and does not represent any real companies or individuals.
            </p>

            <h2>2. How do I get support if I have issues with Car Repair Demo?</h2>
            <p>If you encounter any issues or have questions about using Car Repair Demo, you can contact our support team via email at <a href="mailto:support@codenurturers.com">support@codenurturers.com</a>. We’re here to help!</p>

        </div>
    )
}